<template>
  <Page mode="jacket">
    <h1>{{labels.Title}}</h1>
    <p v-html="labels.Description"></p>
    <div class="vgs-form-indent text-left">
      <RouteLink route="signin" icon="arrow-right" :text="signIn.GoToSignIn"></RouteLink>
    </div>
  </Page>
</template>
<script>
import Page from '@/structure/Page.vue'
import { vcm } from '@/mixins/vcm'
import { vvm } from '@/mixins/vvm'
import { customLabels } from "@/mixins/customLabels";
import RouteLink from '@/structure/RouteLink.vue'
export default {
  mixins: [vcm,vvm, customLabels],
  name: 'EmailValidate',
  components: {
    Page, RouteLink
  },
  data() {
      return {
        labels: {
            Title: 'Validierung E-Mail-Adresse',
            Description: `Ihre E-Mail-Adresse konnte erfolgreich validiert werden. Bitte melden Sie sich <a href="">hier</a> an.`,
        },
      };
    },
    mounted() {
      let queryType = this.$route.query.type;
      this.labels.Description = queryType === 'success' ? `Ihre E-Mail-Adresse konnte erfolgreich validiert werden. Bitte melden Sie sich <a href="/anmeldung">hier</a> an.` : 'Ihre E-Mail-Adresse wurde überprüft. Fehlgeschlagen. Bitte wenden Sie sich an den Administrator <a href="/feedback"> hier </a>.'
    }
}
</script>
<style scoped>
h1 { font-size: 1.875rem; line-height: 2.563rem; color: #121212; }
p { font-size: 1rem; line-height: 1.625rem; color: #121212; }
a{color: $primaryColor; text-decoration: underline;}
p > a {
  color: $primaryColor; text-decoration: underline;
}
</style>

